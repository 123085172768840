/** @jsx jsx */
import React from 'react'
import { css, jsx } from '@emotion/react'
import Layout from '../components/Layout'
import ThemeWrapper from '../components/Theme'
import { Column, ColumnWrap, ColumnWrapper, Title, Image, Subtitle, Text, ContactForm } from '@swp/components'
import SiteHeader from '../components/SiteHeader'

export default function Page(props) {
  return (
    <ThemeWrapper>
      <Layout title={"Kontaktní formulář"}>
        <SiteHeader set="" currentLanguage={undefined} />

        <Column className="pb--80" name={"informace-2"} layout={"l13"}>
        </Column>


        <Column className="pb--60 pt--60" name={"2kw6hrrj19c"} layout={"l2"}>
          
          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
            
            <ColumnWrapper >
              
              <Title className="title-box" content={"Kontaktujte nás"}>
              </Title>

            </ColumnWrapper>

          </ColumnWrap>

          <ColumnWrap className="column__flex --center el--2" columns={"2"}>
            
            <ColumnWrapper >
              
              <Image style={{"maxWidth":60}} alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/o/i/moduls/email-flat-1.svg"} sizes={"(min-width: 40em) 28vw, 100vw"} RootClassName={"column__icon"}>
              </Image>

              <Subtitle className="subtitle-box" content={"info@skolainovace.cz"}>
              </Subtitle>

              <Text className="text-box" content={"Napište nám!"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image style={{"maxWidth":60}} alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/o/i/moduls/phone-flat-1.svg"} sizes={"(min-width: 40em) 28vw, 100vw"} RootClassName={"column__icon"}>
              </Image>

              <Subtitle className="subtitle-box" content={"+420 795 435 923"}>
              </Subtitle>

              <Text className="text-box" content={"Zavolejte nám!"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
            
            <ColumnWrapper >
              
              <ContactForm className="--shape2 --style1" action={"/contact"} fields={[{"name":"Celé jméno","type":"text","required":true,"placeholder":"Zadejte celé jméno","id":"p99oa4kgr961"},{"name":"Kontaktní e-mail","type":"email","required":true,"placeholder":"Zadejte Váš e-mail","id":"b3vslwfz79yb"},{"name":"Telefonní číslo","type":"text","placeholder":"Telefon +420","id":"ry3ea3ahb7tu"},{"name":"Zpráva","type":"textarea","required":true,"placeholder":"Zadejte text zprávy","id":"rbwhbavr0y61"},{"name":"Odeslat","type":"submit","id":"szcaog4xu3r"}]}>
              </ContactForm>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--60 pt--60" name={"57evw1obpz"}>
          
          <ColumnWrap className="column__flex el--1" columns={"1"}>
            
            <ColumnWrapper style={{"maxWidth":800}}>
              
              <Text className="text-box" content={"Odesláním kontaktního formuláře zákazník souhlasí se <a href=\"/-Kopie-1sz7384sscr-Kopie-bqzgmn1h6w-Kopie-wrxselsjyio\">zpracováním osobních údajů</a><br>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--60 pt--60" style={{"backgroundColor":"var(--color-supplementary)"}} name={"paticka"} layout={"l1"}>
          
          <ColumnWrap className="column__flex --left el--2" style={{"maxWidth":1450}} columns={"2"}>
            
            <ColumnWrapper style={{"maxWidth":""}}>
              
              <Title className="title-box" content={"<span style=\"color: var(--color-dominant);\">Skolainovace.cz</span>"}>
              </Title>

              <Text className="text-box" style={{"maxWidth":375}} content={"<span style=\"color: var(--white);\">skolainovace.cz, s. r. o.<br>Email: info@skolainovace.cz<br>Telefon: 795 435 923<br>IČ:&nbsp;21803668<br>Malá Štěpánská 1930/7<br>120 00 Praha 2</span><br>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper className="--right" style={{"maxWidth":""}}>
              
              <Title className="title-box" content={"Zadejte text"}>
              </Title>

              <Text className="text-box" style={{"maxWidth":""}} content={"<a href=\"/o_nas\" style=\"color: var(--white);\">O nás</a><a href=\"/blog\" style=\"color: var(--white);\"><br></a><a href=\"/nabidka-kurzu\" style=\"color: var(--white);\">Nabídka kurzů</a><a href=\"/blog\" style=\"color: var(--white);\"><br>Blog<br></a><a href=\"https://www.facebook.com/profile.php?id=61559873844823\" target=\"_blank\" style=\"color: var(--white);\">Facebook</a><a href=\"/blog\" style=\"color: var(--white);\"><br></a><a href=\"/zasady-zpracovani-osobnich-udaju\" style=\"color: var(--white);\">Zásady zpracování osobních údajů</a><br>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>

      </Layout>
    </ThemeWrapper>
  )
}